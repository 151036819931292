:root {
  --bg-color: #ffffff;
  --txt-color: #171717;
  --txt-color-light: #ffffff;
  --accent-color: #0f0f0f;
  --accent2-color: #dddddd;
  --inactive-color: #d3d3d3;
  --mid-color: #f7f7f7;
  --dark-color: #ffffff;
}

body {
  margin-bottom: 100px;
  padding-bottom: 0px;
  background-color: var(--bg-color) !important;
}

/* ########## PLACE SECTION ########## */

#logo {
  width: 110px;
  padding: 0px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.magazi {
  font-size: 13px;
  font-family: sans-serif;
  font-weight: 300;
  text-align: center;
  color: var(--txt-color);
  /*   justify-content: center;
 */
}

.clearhere {
  clear: left;
}

.menu-btn {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  margin: 25px 0px 0px 25px;
  padding: 03px 0px 0px 0px;
  position: absolute;
  left: 0;
  background-color: var(--accent-color);
  font-size: 13px;
  color: var(--txt-color-light);
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
  box-shadow: 0;
}

embed {
  width: 100%;
  height: 100%;
  z-index: 100;
}

.menupdf {
  height: 100%;
}

.menubtn {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin: 25px 0px 0px 25px;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
}

/* ########## ITEMS SECTION ########## */

.list {
  text-align: left;
  max-width: 800px;
  font-size: 16px;
  font-weight: 400;
}

.list-group {
  box-shadow: 0px;
  border-width: 0px;
  border-radius: 0px;
  border: 0px solid #000000;
  padding-bottom: 40vh;
}

.list-group-chooseThing {
  box-shadow: 0px;
  border-width: 0px;
  border-radius: 0px;
  border: 0px solid #000000;
  padding: 0px;
  margin: 0px 0px 20px 0px;
}

.list-group-item {
  border-top: 0px solid #000000;
  border-bottom: 0px solid var(--txt-color);
  border-left: 0px solid #000000;
  border-right: 0px solid #000000;
  background-color: var(--mid-color);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 3px;
  color: var(--txt-color);
}

.list-group-item-chooseThing {
  list-style: none;
  border-top: 0px solid #000000;
  border-bottom: 0px solid var(--txt-color);
  border-left: 0px solid #000000;
  border-right: 0px solid #000000;
  background-color: var(--mid-color);
  padding: 7px 0px 10px 20px;
  margin-top: 3px;
  color: var(--txt-color);
}

.list-group-item:last-child {
  border-top: 0px solid #000000;
  border-bottom: 0px solid #000000;
  border-left: 0px solid #000000;
  border-right: 0px solid #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--txt-color);
}

.inlinediv {
  display: inline;
}

.paid-li {
  padding-left: 40px;
  background-color: var(--inactive-color);
}

.itemsinthelist {
  float: left;
}

.prices {
  text-align: right;
}

/* Hide the default checkbox */
input[type="checkbox"] {
  appearance: none; /* Removes default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  top: 5px;
  width: 20px;
  height: 20px;
  border: 1.5px solid #555;
  border-radius: 2px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Checkmark (hidden by default) */
input[type="checkbox"]::before {
  content: "✓";
  font-size: 16px;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

/* Change background when checked */
input[type="checkbox"]:checked {
  background: #000000;
  border-color: #000000;
}

/* Show checkmark when checked */
input[type="checkbox"]:checked::before {
  transform: translate(-50%, -50%) scale(1);
}

/* ########## PAYMENT SECTION ########## */

.arxikiSection3 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--bg-color);
  border-top: 0px solid #000000;
  margin: 0px 0px;
  padding: 15px 10px 10px 10px;
  /* transition: height 0.3s ease; */
}

hr {
  display: block;
  width: 25%;
  height: 1px;
  border: 0;
  border-top: 1px solid hsl(0, 0%, 75%);
  margin: 1px 0px 1px auto;
  padding: 0;
}

.toPayAmUnderRem {
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  color: var(--txt-color);
}

.nooneHasPaid {
  color: var(--txt-color);
  font-size: 13px;
  font-weight: 200;
}

.btnpay {
  margin: 0px auto 0px auto !important;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  padding: 12px 40px 12px 40px;
  border-color: black;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  box-shadow: none !important;
  border-radius: 2px;
}

.btnpay1stpage {
  margin: 60px auto 0px auto !important;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  padding: 12px 40px 12px 40px;
  border-color: black;
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  box-shadow: none !important;
  border-radius: 2px;
}

.btnpay2 {
  margin: 7px auto 0px auto !important;
  background-color: var(--bg-color);
  color: var(--txt-color);
  /* color: #ccd4ff; */
  padding: 12px 40px 12px 40px;
  border: 2px solid;
  border-color: var(--accent-color);
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  box-shadow: none !important;
  border-radius: 2px;
}

.btnbk {
  margin: 0px 20px 15px auto !important;
  padding: auto;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  border: 1px solid #ffffff;
  width: 90px;
  font-size: 16px;
  height: 45px;
  font-weight: 300;
}

.tipTitle {
  text-align: left;
  font-size: 15px;
}

.tipz {
  margin: 8px 0px 18px 0px;
  width: 100%;
  padding: 0px 0px 0px 0px;
  text-align: center;
  border: 0px solid var(--accent-color);
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.tip {
  font-size: 13px;
  background-color: var(--bg-color);
  color: var(--txt-color);
  border: 1px solid var(--accent2-color);
  border-radius: 2px;
}

.tip.active {
  background-color: var(--accent-color);
  color: var(--txt-color-light);
  border: 1px solid var(--accent-color);
  border-radius: 2px;
}

.tipz button {
  margin: 0px;
  flex-grow: 1;
  flex-basis: 0;
  width: auto;
  padding: 0;
  height: 50px;
}

.noTip {
  width: 40px !important;
}

.disableTip {
  visibility: collapse;
}

.tipz .tipform {
  text-align: center;
  margin: 0px;
  padding: 0px;
  flex-grow: 1;
  flex-basis: 0;
  width: auto;
  height: 100%;
  border: 0px solid var(--accent2-color);
  background-color: var(--bg-color);
  border-radius: 0px;
  font-size: 14px;
  box-shadow: none !important;
  box-sizing: border-box;
}

.tipz .tipform input {
  border: 0px;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  box-shadow: none !important;
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 13px;
  border: 1px solid var(--accent2-color);
  border-radius: 2px;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--accent-color);
  color: var(--txt-color-light);
}

input::placeholder {
  font-weight: 200;
  color: var(--txt-color);
}

.form1 {
  border: 0px;
  margin: 0px 0 0px 0;
  padding: 0px 0px 0px 0px;
  box-shadow: none !important;
}

.form2 {
  border: 0;
  width: 100%;

  margin: 0px auto 20px auto;
  padding: 0px 0px 0px 0px;
  box-shadow: none !important;
}

.form2 input {
  border: 1px solid var(--accent2-color);
  border-radius: 2px;
  width: 100%;
}

.customAmount {
  margin: 10px 0 0 0;
}

.customAmountField {
  border: 0px;
  margin: 6px 0 0px 0;
  padding: 0px 0px 0px 16px;
  box-shadow: none !important;

  height: 50px;
  text-align: left;
}

.euroInInput {
  margin-left: -2em;
  margin-right: 10px;
  color: var(--txt-color-light);
}

.euroInInputSmall {
  margin-left: -1.1em;
  margin-right: 5px;
  color: var(--txt-color-light);
}

/* HIDE ARROWS IN INPUT FIELD */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  display: inline-block;
}

.totalsContainer {
  margin: 0 15px -52px 0;
  padding: 0px 0 05px 0;
}

.total {
  text-align: right;
  padding-left: 0px;
  padding-top: 0px;
  font-weight: 500;
}

.totalinvaded {
  text-align: right;
  padding: 0px 0 0 0;
  font-weight: 400;
  color: #b8b8b8;
}

.remaining {
  text-align: right;
  font-weight: 400;
  font-size: 16px;
}

.tipAfterTotal {
  text-align: right;
  font-size: 16px;
}

.input-number {
  width: 50px !important;
  text-align: center !important;
  font-weight: bold !important;
  font-size: 25px !important;
  height: 40px !important;
  border: none !important;
  border-bottom: solid 2px #ccc !important;
  box-shadow: none !important;
  position: relative;
}
.input-container {
  position: relative;
  height: 40px;
  display: block;
  margin: 0 15px;
}
.input-container:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px;
  height: 10px;
  background-color: #ccc !important;
  z-index: 10;
}
.input-container:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2px;
  height: 10px;
  background-color: #ccc !important;
  z-index: 10;
}
.btn-number {
  border-radius: 50% !important;
  background-color: #ccc !important;
  color: #fff !important;
  outline: none !important;
  font-size: 25px !important;
  padding: 3px !important;
  width: 40px;
  height: 40px;
}

.division {
  margin: 15px 0 20px 0;
}

.counter {
  width: 300px;
  margin: auto;
  border-radius: 5px;
  padding: 0px;
  text-align: center;
}
.counter h1 {
  margin: 0;
  padding: 0px 0 8px 0;
  font-size: 14px;
  font-weight: normal;
}

.highlight {
  font-size: 17px;
  font-weight: 600;
}

.counter button {
  background: var(--accent-color);
  border: 0;
  padding: 10px;
  margin: 0px 4px 0 5px;
  width: 45px;
  height: 45px;
  outline: none;
  border-radius: 100px;
  color: hsl(0, 0%, 100%);
  font-weight: normal;
}

.c1 {
  margin-bottom: 10px;
}

.c1 button {
  background: var(--accent-color);
  border: 0;
  padding: 10px;
  margin: 0px 4px 0 5px;
  width: 55px;
  height: 55px;
  outline: none;
  border-radius: 100px;
  color: hsl(0, 0%, 100%);
  font-weight: normal;
}

/*  PAID ORDER  */

.paidOrder {
  text-align: center;
  margin: 80px 0 40px 0;
  font-size: 14px;
}

/* ############### MODAL CSS ################# */

/* Prevent scrolling */
.no-scroll {
  overflow: hidden;
}

.modal {
  position: fixed;
  width: 100%;
  height: 85%;
  background: var(--bg-color);
  margin: 0;
  padding: 0;
}

.modal-main {
  position: fixed;
  background: white;
  width: 100%;
  height: auto;
  top: auto;
  right: auto;
  left: auto;
  bottom: 0;
  margin: 0;
  padding: 20px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.CloseButton {
  width: 120px;
  height: 50px;
  padding: 0 0 0 0;
  margin: 0 0 20px 0;
}

/* ############### STRIPE CSS ############### */
#root {
  display: flex;
  align-items: center;
}

.body_stripe {
  /*de kanei tpt gia tin wra, itan sketo body*/
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  display: inline-block;
  margin: 0;
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: hsl(0, 0%, 0%);
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  /* display: block; */
  /* transition: all 0.2s ease; */
  /* box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07); */
  width: 100%;
}

/* button:hover {
  filter: contrast(115%);
} */

button:disabled {
  opacity: 0.3;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

/* ################ SUCCESS PAYMENT PAGE ################ */

.successPayment {
  margin: 40px 0 0 0;
  text-align: center;
  color: green;
}

/* ################ 404 PAGE ################ */

.page404 {
  text-align: center;
}
